<template>
  <div>
    <div class="page-title">原辅料基础数据管理</div>
    <div style="float:left;width:100%;margin-top:1rem">
      <el-select
        v-model="chg"
        clearable
        placeholder="搜索项"
        size="mini"
        style="width:120px"
      >
        <el-option
          v-for="item in options"
          :key="item"
          :label="item"
          :value="item"
        ></el-option>
      </el-select>
      <el-input
        v-model="keywords"
        placeholder="请输入搜索关键词"
        @keydown.enter.native="getRawList_"
        clearable
        size="mini"
        style="width:280px"
      ></el-input>
      <el-button
        type="primary"
        size="mini"
        icon="el-icon-search"
        @click="getRawList_"
      > 搜索
      </el-button>
      <el-button type="danger" size="mini" icon="el-icon-document-add" @click="showUpLayer"> 导入数据</el-button>
      <el-button type="success" size="mini" icon="el-icon-document-checked" @click="outputRawInfo"> 导出数据</el-button>
      <el-button type="warning" size="mini" icon="el-icon-plus" @click="showAddLayer"> 手动添加</el-button>
    </div>
    <div style="margin-top: 1rem;float: left;width: 100%">
      <el-table
        class="tb-edit"
        :data="rawData"
        border
        style="width: 100%"
        highlight-current-row
        @current-change="handleCurrentChange"
      >
        <el-table-column prop="分类" label="分类"></el-table-column>
        <el-table-column prop="品名" label="品名"></el-table-column>
        <el-table-column prop="规格" label="规格"></el-table-column>
        <el-table-column prop="单位" label="单位"></el-table-column>
        <el-table-column label="操作" width="160">
          <template slot-scope="scope">
            <el-button
              size="mini"
              @click="handleEdit(scope.$index, scope.row)">编辑
            </el-button>
            <el-button
              size="mini"
              type="danger"
              @click="handleDelete(scope.$index, scope.row)">删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div style="width: 100%;float:left;margin-top: 1rem;">
      <el-pagination
        @current-change="handlePageChange"
        :current-page="whichPage"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="count">
      </el-pagination>
    </div>
    <el-dialog title="添加原辅料的基础信息" :visible.sync="addLayer" width="580px" :close-on-click-modal="false">
      <el-form :model="rawAddForm">
        <el-form-item label="分类" label-width="110px">
          <el-select
            v-model="rawAddForm.分类"
            filterable
            allow-create
            placeholder="请选择"
            default-first-option
          >
            <el-option
              v-for="item in rawCate"
              :key="item.分类"
              :label="item.分类"
              :value="item.分类">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="品名" label-width="110px">
          <el-input v-model="rawAddForm.品名" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="规格" label-width="110px">
          <el-input
            v-model="rawAddForm.规格"
            placeholder="多个规格属性请用（、）分开"
          ></el-input>
        </el-form-item>
        <el-form-item label="计量单位" label-width="110px">
          <el-input v-model="rawAddForm.单位" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addLayer = false">取 消</el-button>
        <el-button type="primary" @click="rawInfoSubmit('add')">添 加</el-button>
      </div>
    </el-dialog>
    <el-dialog title="修改原辅料的基础信息" :visible.sync="editLayer" width="580px" :close-on-click-modal="false">
      <el-form :model="rawEditForm">
        <el-form-item label="分类" label-width="110px">
          <el-select v-model="rawEditForm.分类" filterable allow-create placeholder="请选择">
            <el-option
              v-for="item in rawCate"
              :key="item.分类"
              :label="item.分类"
              :value="item.分类">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="品名" label-width="110px">
          <el-input v-model="rawEditForm.品名" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="规格" label-width="110px">
          <el-input
            v-model="rawEditForm.规格"
            placeholder="多个规格属性请用（、）分开"
          ></el-input>
        </el-form-item>
        <el-form-item label="计量单位" label-width="110px">
          <el-input v-model="rawEditForm.单位" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editLayer = false">取 消</el-button>
        <el-button type="primary" @click="rawInfoSubmit('edit')">修 改</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="导入原辅料的基础信息"
      :visible.sync="uploadLayer"
      width="420px"
      :close-on-click-modal="false"
    >
      <el-upload
        ref="upload"
        class="upload-demo"
        drag
        :action="uploadUrl"
        :on-success="function(res){return uploadOver(res)}"
        :before-upload="function(file){ return beforeUpload(file)}"
        :data="{token}"
        :headers="{token}"
        :show-file-list="false"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip">1. 只能上传 xlsx 格式的文件</div>
        <div class="el-upload__tip" slot="tip">2. 表格中必须包含列：分类、品名、料号、规格、单位</div>
        <div class="el-upload__tip" slot="tip">3. 上传完成后，程序将只导入第 1 张工作表的数据</div>
      </el-upload>
      <span slot="footer" class="dialog-footer">
        <el-button @click="uploadLayer = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {baseUrl, baseTitle} from '@/configs/squareConfig'
import {getRawList, addRawInfo, editRawInfo, getRawCate} from '@/http/api'
import {Loading} from 'element-ui'
import {formRequest} from '@/http/formRequest'

let loading
export default {
  name: "RawInfo",
  data() {
    return {
      chg: '',
      options: ['品名', '料号', '规格信息'],
      keywords: '',
      rawData: [],
      page: 0,
      rawAddForm: {
        品名: '',
        规格: '',
        单位: '',
        分类: '',
      },
      rawEditForm: {},
      addLayer: false,
      editLayer: false,
      whichPage: this.page + 1,
      count: 0,
      pageSize: 100,
      maxPage: 1,
      rawCate: [],
      uploadLayer: false,
      uploadUrl: baseUrl + '/raw-info-upload',
      token: this.$cookies.get('token'),
    }
  },
  methods: {
    rawInfoSubmit(typ) {
      let rawInfoData
      typ === 'add' ? rawInfoData = this.rawAddForm : rawInfoData = this.rawEditForm
      for (let rawKey in rawInfoData) {
        if (rawInfoData[rawKey] === '') {
          this.$message({
            message: '请输入完整后再提交',
            type: 'warning'
          })
          return
        }
      }
      const token = this.token
      if (typ === 'add') {
        addRawInfo({rawInfoData, token})
          .then(res => {
            if (res.data.code === 'OK') {
              this.$message({
                message: res.data.msg,
                type: 'success',
              })
              this.getRawList_()
              this.getRawCate_()
              this.addLayer = false
            }
          })
          .catch(err => console.log(err))
      }
      if (typ === 'edit') {
        editRawInfo({rawInfoData, token})
          .then(res => {
            if (res.data.code === 'OK') {
              this.$message({
                message: res.data.msg,
                type: 'success',
              })
              this.getRawList_()
              this.getRawCate_()
              this.editLayer = false
            }
          })
          .catch(err => console.log(err))
      }
    },
    handleEdit(index, row) {
      this.rawEditForm = row
      this.editLayer = true
    },
    handleDelete(index, row) {
      console.log(index, row)
      this.$message({
        message: '暂不支持删除',
        type: 'warning',
      })
    },
    handleCurrentChange(row, oldRow) {
      // console.log(row)
      // console.log(oldRow)
    },
    handlePageChange(item) {
      console.log(item)
    },
    showAddLayer() {
      this.addLayer = true
    },
    getRawList_() {
      const page = this.page
      const chg = this.chg
      const keywords = this.keywords
      const token = this.token
      getRawList({
        page,
        chg,
        keywords,
        token,
      }).then(res => {
        this.rawData = res.data.rawData
        this.count = res.data.count
        this.page = res.data.page
        this.pageSize = res.data.pageSize
        this.maxPage = res.data.maxPage
        this.formatRawData()
      })
        .catch(err => console.log(err))
    },
    getRawCate_() {
      getRawCate({token: this.token})
        .then(res => {
          this.rawCate = res.data.rs
        })
        .catch(err => console.log(err))
    },
    formatRawData() {
      const oldData = this.rawData
      this.rawData = []
      oldData.forEach(raw => {
        raw.规格 = JSON.parse(raw.规格).join('、')
        this.rawData.push(raw)
      })
    },
    showUpLayer() {
      this.uploadLayer = true
      if (this.$refs.upload) this.$refs.upload.clearFiles()
    },
    beforeUpload(file) {
      loading = Loading.service({fullscreen: true})
    },
    uploadOver(res) {
      loading.close()
      this.$refs.upload.clearFiles()
      if (res.code !== 'OK') {
        this.$message({
          message: res.msg,
          type: 'error'
        })
      } else {
        this.$message({
          message: res.msg,
          type: 'success'
        })
        this.getRawList_()
        this.getRawCate_()
        this.uploadLayer = false
      }
    },
    outputRawInfo() {
      const token = this.token
      const chg = this.chg
      const keywords = this.keywords
      let url = baseUrl + '/raw-info-output'
      formRequest(url, {token, chg, keywords})
    }
  },
  activated() {
    this.getRawList_()
    this.getRawCate_()
    document.title = '原辅料基础数据管理 - ' + baseTitle
  },
}
</script>

<style scoped>

</style>
